.resource-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  position: relative;
  overflow: hidden;

  &__image-container {
    width: 100%;
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      inset: 0;
      background: var(--cl-white);
      content: "";
      z-index: -1;
    }
  }

  &__category {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--cl-tangerine);
    padding: ac(10px, 8px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 5;

    p {
      font-family: var(--font-main);
      font-size: ac(15px, 13px);
      font-weight: 600;
      line-height: normal;
      color: var(--cl-black);
    }

    &:last-child:first-child {
      position: relative;
    }
  }

  &:not(.full-type) {
    .resource-card__image {
      @mixin aspect-ratio 394, 314;
      width: 100%;
    }
  }

  &__image {
    img {
      transition: transform 0.3s ease;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-white);
    padding: ac(27px, 24px) ac(27px, 20px);
  }

  &__title {
    font-size: ac(30px, 22px);
    line-height: 1.33;
    font-weight: 400;
    color: var(--cl-black) !important;
    @mixin max-line-length 3;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__text {
    margin-top: auto;
    @mixin max-line-length 3;

    p,
    li {
      color: var(--cl-black);
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &:hover {
    .resource-card {
      &__image {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &:not(.full-type) {
    .resource-card {
      &__content {
        width: 100%;
      }
    }
  }

  &.full-type {
    @media (min-width: 551px) {
      padding-top: ac(292px, 150px);
      overflow: hidden;

      .resource-card {
        &__image {
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__image-container {
          position: static;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__category {
          position: relative;
          top: auto;
          left: auto;
          width: auto;
        }

        &__content {
          position: relative;
          z-index: 5;
          max-width: ac(394px, 340px);
        }
      }
    }
  }

  &.min-type {
    .resource-card {
      &__image {
        @mixin aspect-ratio 394, 311;
        width: 100%;

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          height: 79%;
          background: linear-gradient(
            180deg,
            rgba(28, 28, 28, 0) 0%,
            #1c1c1c 100%
          );
          content: '';
          z-index: 1;
        }
      }

      &__content {
        background-color: transparent;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
      }

      &__title {
        font-size: ac(25px, 20px);
        line-height: 140%;
        font-weight: 400;
        color: var(--cl-beige) !important;
        margin-bottom: 0;
      }

      &__item-links {
        display: none;
      }
    }
  }

  &.wat-we-do-type {
    .resource-card {
      &__content {
        padding-left: ac(48px, 28px);
        padding-top: ac(27px, 24px);
        padding-bottom: ac(27px, 24px);
        padding-right: ac(27px, 20px);
        position: relative;
        min-height: ac(300px, 240px);

        &:after {
          position: absolute;
          top: ac(27px, 24px);
          bottom: ac(27px, 24px);
          left: ac(27px, 12px);
          width: 4px;
          background: #CBE2B8;
          content: "";
        }
      }

      &__image {
        @mixin aspect-ratio 394, 260;
        width: 100%;
      }

      &__text {
        margin-top: 0;
        @mixin max-line-length 8;
      }

      &__title {
        font-weight: 600;
        font-family: var(--font-third) !important;
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 0;
    }
  }
}
