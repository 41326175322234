.banner-section {
  --small-img: ac(300px, 150px);
  --standard-img: ac(500px, 300px);
  --medium-img: ac(600px, 350px);
  --big-img: ac(700px, 400px);
  --biggest-img: ac(800px, 450px);

  --image-height: var(--standard-img);

  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__bg {
    position: relative;
  }

  &__bg-image {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (min-width: 1600px) {
        object-position: 50% 10%;
      }

      @media (min-width: 1800px) {
        object-position: 50% 20%;
      }

      @media (min-width: 1900px) {
        object-position: 50% 25%;
      }
    }

    &:before {
      position: absolute;
      inset: 0;
      content: none;
      background: linear-gradient(269.94deg, rgba(0, 0, 0, 0) 26.54%, rgba(0, 0, 0, 0.7) 99.91%);
      z-index: 1;
    }

    &.add-bg {
      &:before {
        content: "";
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 40px);
    min-height: var(--image-height);
  }

  &__content {
    width: 100%;
    max-width: ac(800px, 550px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &.pb-type {
    --none: 0;
    --small: ac(40px, 20px);
    --standard: ac(80px, 40px);
    --medium: ac(100px, 50px);
    --big: ac(120px, 60px);
    --biggest: ac(150px, 80px);

    --gap: var(--standard);

    padding-top: var(--gap);
    padding-bottom: 0;
  }

  @media (min-width: 1980px) {
    --small-img: 400px;
    --standard-img: 650px;
    --medium-img: 700px;
    --big-img: 800px;
    --biggest-img: 900px;
  }
}