.text-team-section {
  padding-top: ac(100px, 50px);
  padding-bottom: ac(40px, 20px);
  z-index: 20;
  overflow: visible;

  @mixin media 851 {
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.1%;

    @mixin media 1290 {
      width: 55%;
    }

    @mixin media 1200 {
      width: 50%;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(34px, 16px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    ul,
    ol {
      li {
        line-height: 155.56%;
        padding-left: ac(16px, 14px);

        &:before {
          left: 0;
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__item {
    margin-top: ac(92px, 40px);
    display: flex;
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 30px);
    padding-right: ac(47px, 20px);
    position: relative;
    z-index: 10;
    width: 44.04%;
    margin-right: ac(-47px, -20px);

    @mixin min-media 851 {
      position: sticky;
      top: 90px;
      right: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: ac(76px, 35px);
      background: var(--cl-tangerine);
      content: '';
      z-index: -1;
    }

    &[data-view] {
      &.animated {
        .text-stats-section {
          &__item-progressbar {
            span {
              width: var(--percent);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__item-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-beige);
    padding: ac(47px, 24px) ac(47px, 20px);
  }

  &__item-title {
    margin-top: 1px;
    font-size: ac(30px, 24px);
    line-height: 1.18;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: ac(25px, 16px);
    }
  }

  &__item-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    li {
      border-top: 1px solid var(--cl-black);
      padding-top: ac(10px, 8px);
      padding-bottom: ac(10px, 8px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: ac(20px, 18px);
      font-weight: 600;
      line-height: 1.55;
      font-family: var(--font-main);

      &:last-child {
        border-bottom: 1px solid var(--cl-black);
      }

      a {
        font: inherit;
        transition: color 0.3s ease;
        max-width: 100%;
        @mixin max-line-length-one;

        &:hover {
          color: var(--cl-sunflower);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__item-socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 10px;
  }

  &__item-social {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transition: color 0.3s ease;

    &:hover {
      color: var(--cl-sunflower);
    }
  }

  @media (max-width: 1280px) {
    .text-team-section {
      &__item {
        width: 50%;
      }
    }
  }

  @media (max-width: 851px) {
    .text-team-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__content {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      &__item {
        margin-top: 30px;
        width: 100%;
        max-width: 420px;
        margin-right: 0;

        @mixin min-media 390 {
          padding-right: 30px;
        }
      }
    }
  }
}
