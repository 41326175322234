.capabilities-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title {
    width: 100%;

    h2 {
      font-size: ac(90px, 44px);
      font-weight: 600;
      line-height: 111.11%;

      @media (min-width: 1280px) {
        letter-spacing: -1px;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(60px, 30px);
    }
  }

  &__slider-buttons {
    @mixin min-media 951 {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__list {
    margin-top: ac(8px, 0px);
    width: calc(28.66% + 5px);
    padding-left: ac(106px, 0px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin media 951 {
      display: none;
    }

    li {
      font-size: ac(25px, 20px);
      line-height: 1.4;
      transition: all 0.3s ease;
      padding-left: 0;
      font-weight: 700;
      color: var(--cl-black);
      position: relative;
      cursor: pointer;
      font-family: var(--font-main);
      opacity: 0.4;
      transform-origin: left;

      &:not(:last-child) {
        margin-bottom: ac(30px, 16px);
      }

      &.active {
        opacity: 1;
        color: var(--cl-sunflower);
        transform: scale(1.2);

        @mixin media 1290 {
          transform: scale(1.1);
        }
      }

      &:not(.active) {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__slider {
    width: 65.86%;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;

    @mixin media 951 {
      width: 100%;
    }
  }

  &__item {
    border-top: 1px solid var(--cl-black);
    padding-top: ac(30px, 24px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(.swiper-slide-active) {
      @mixin min-media 951 {
        opacity: 0 !important;
      }
    }
  }

  &__item-title {
    font-family: var(--font-second);
    font-size: ac(30px, 22px);
    line-height: 133.33%;
    font-weight: 500;

    @media (min-width: 1280px) {
      letter-spacing: 0.4px;
    }

    &:not(:last-child) {
      margin-bottom: ac(25px, 16px);
    }
  }

  &__item-text {
    &.small-size {
      p {
        font-size: 16px;
        line-height: ac(26px, 24px);

        @media (min-width: 1280px) {
          letter-spacing: -0.16px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    /*&[data-simplebar] {
      width: calc(100% + 10px);
      padding-right: 10px;

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-background);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-sunflower);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }
    }*/
  }

  &__item-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: ac(30px, 18px);
    grid-row-gap: ac(40px, 24px);

    @mixin media 1050 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @mixin media 551 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @mixin max-xs {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__item-card {
    border-top: 1px solid var(--cl-black);
    padding-top: 11px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
   /* min-height: ac(93px, 80px);*/
    min-height: initial;
  }

  &__item-card-title {
    font-weight: 400;
    font-size: ac(24px, 22px);
    line-height: 1.42;
    font-family: var(--font-main);

    @media (min-width: 1280px) {
      letter-spacing: -0.5px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__item-card-link {
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 44px;
  }

  &.careers-page {
    padding-bottom: ac(68px, 20px);

    .capabilities-section {

      &__title {
        h2 {
          font-size: ac(70px, 38px);
          font-weight: 600;
          line-height: 1.11;
          font-family: var(--font-main);

          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__list {
        padding-left: 0;
        width: 20.13%;
      }

      &__slider {
        @mixin min-media 951 {
          width: 74.40%;
          margin-top: 4px;
        }
      }

      &__item-title {
        color: var(--cl-sunflower);

        &:not(:last-child) {
          margin-bottom: ac(31px, 20px);
        }
      }
    }

    &.careers-candidate-page {
      .capabilities-section {
        &__list {
          li:not(:last-child) {
            margin-bottom: ac(33px, 20px);
          }
        }
      }
    }

    @media (max-width: 639px) {
      padding-top: 20px;
      margin-bottom: 0;
    }

    @media (max-width: 425px) {
      .capabilities-section {
        &__title {
          h2 {
            br {
              display: none;

              + span {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
