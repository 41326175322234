.meta-input,
.meta-upload-file {
  label,
  .label {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 400;
    color: var(--cl-white);
    padding-bottom: ac(15px, 11px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-tangerine);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-size: ac(20px, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: var(--font-main);
    color: var(--cl-black);
    border: none;
    border-bottom: 1px solid var(--cl-black);
    border-radius: 0;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 0 0 10px 0;
    height: 42px;
    background: transparent;
    @mixin transparent-bg-input var(--cl-black);

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-black);
      opacity: 0.5;
    }

    &:focus {
      border-color: var(--cl-sunflower);
      caret-color: var(--cl-black);
      /*box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);*/
    }

    /*&.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }*/
  }

  textarea {
    padding-top: 0;
    min-height: ac(100px, 60px);
    max-height: ac(100px, 60px);
    overflow-y: auto;
    resize: none;
    /*clip-path: inset(-2px -2px -2px -2px round 17px);*/

    scrollbar-width: thin;
    scrollbar-color: var(--cl-sunflower) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-blue);
      border-radius: 2px;
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-white);
  }

  /*&__error {
    color: var(--cl-red);
  }*/

  &.search-type {
    position: relative;

    input {
      padding-right: 30px;
    }

    .search-btn {
      position: absolute;
      top: 6px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      transition: color 0.3s ease;
      color: var(--cl-black);

      &:hover {
        color: var(--cl-sunflower);
      }
    }
  }

  &.form-type {
    background: var(--cl-white);
    height: ac(64px, 56px);
    padding-top: ac(8px, 6px);
    padding-left: ac(20px, 10px);

    label,
    .label {
      color: var(--cl-sunflower);
      font-size: ac(14px, 12px);
      font-weight: 600;
      padding-bottom: 3px;
    }

    input,
    textarea {
      border: none;
      font-family: var(--font-second);
      font-weight: 400;
      font-size: ac(17px, 16px);
      padding-bottom: ac(12px, 8px);
      padding-left: 10px;
      padding-right: 10px;
    }

    textarea {
      min-height: ac(78px, 60px);
      max-height: ac(78px, 60px);
      height: ac(78px, 60px);
    }

    &.textarea-type {
      height: auto;
    }
  }

  &.form-second-type {
    background-color: var(--cl-beige);
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label,
  .label {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 400;
    color: var(--cl-white);
    padding-bottom: ac(15px, 11px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-purple);
    }
  }

  select {
    opacity: 0;
    height: 50px;
  }

  &__container {
    width: 100%;
    padding: 1px;
    border-radius: 10px;
    position: relative;
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    &:not(.is-open) {
      overflow: hidden;
    }

    &__inner {
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 0 0 10px 0 !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      height: 42px;
      min-height: 42px;
      background: transparent;
      font-size: ac(20px, 18px);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-family: var(--font-main);
      color: var(--cl-black);
      border: none;
      border-bottom: 1px solid var(--cl-black);
      border-radius: 0 !important;
      outline: none;

      /*&:hover {
        border-color: var(--cl-orange);
      }*/
      /*&.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }*/
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: 8px;
        }
      }

      &.is-flipped {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 8px;
        }
      }
    }

    &__item {
      @mixin max-line-length-one;
    }

    &__placeholder {
      color: var(--cl-black);
      opacity: 1;
      background: transparent;
    }

    &__list {
      padding: 0;
      background-color: var(--cl-beige);

      .choices__item {
        padding-right: ac(16px, 12px);
        font-size: ac(20px, 18px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-family: var(--font-main);
        color: var(--cl-black);
        transition: all 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;

        &.is-selected {
          color: var(--cl-sunflower);
        }
      }

      &--single {
        background-color: transparent !important;

        .choices__item {
          color: var(--cl-black);
        }
      }

      &--multiple {
        color: var(--cl-black);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        .choices__item {
          color: var(--cl-black);
          text-decoration: none !important;

          &:hover {
            &:not(.is-selected) {
              color: var(--cl-tangerine) !important;
              text-decoration: none !important;
            }
          }
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        border-bottom: 1px solid var(--cl-black);
        border-right: 1px solid var(--cl-black);
        content: '';
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 5px;
        top: 20px;
        transform-origin: 66% 66%;
        transform: rotate(45deg) scale(2);
        transition: all 0.15s ease-in-out;
        width: 5px;
      }
    }

    &.is-open {
      &:after {
        transform: rotate(-135deg) scale(2);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-black);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 1px solid var(--cl-black);
      z-index: 20;
      border-radius: 0;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 1px solid var(--cl-black);
      border-radius: 0;
      background: var(--cl-background);
      color: var(--cl-black);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  /*&__list {
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 8px;

    &.is-visible {
      margin-top: 10px;
      display: flex;
    }
  }

  &__list-item {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    padding-right: 14px;
    cursor: default;
    color: var(--cl-dark-blue);

    &:after {
      content: '';
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      transition: background-color 0.25s ease, opacity 0.25s ease,
        transform 0.25s ease;
      opacity: 0.5;
      background: var(--cl-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translateY(-50%) scale(1.15);
      }
    }
  }
  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-dark-grey);
  }

  &__error {
    color: var(--cl-red);
  }*/

  &.form-type {
    background: var(--cl-white);
    height: ac(64px, 56px);
    padding-top: ac(8px, 6px);
    padding-left: ac(20px, 10px);
    padding-right: ac(20px, 10px);

    label,
    .label {
      color: var(--cl-sunflower);
      font-size: ac(14px, 12px);
      font-weight: 600;
      padding-bottom: 3px;
    }

    .choices {
      &__inner {
        border: none;
        font-family: var(--font-second);
        font-weight: 400;
        font-size: ac(17px, 16px);
        padding-bottom: ac(12px, 8px);
        height: 34px;
        min-height: 34px;
      }

      &__list {
        &:not(.choices__list--dropdown) {
          .choices__item {
            font-family: var(--font-second);
            font-weight: 400;
            font-size: ac(17px, 16px);
            padding-left: 10px;
          }
        }
      }
    }

    .choices[data-type*='select-multiple']::after,
    .choices[data-type*='select-one']::after {
      top: 3px;
    }
  }

  &.form-second-type {
    background-color: var(--cl-beige);
  }

  &.sort-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    grid-column-gap: ac(15px, 10px);
    min-width: ac(171px, 166px);

    label {
      color: var(--cl-black);
      white-space: nowrap;
      padding: 0;
      font-size: 15px;
      font-family: var(--font-main);
      line-height: normal;
      font-weight: 400;
    }

    .choices {
      &__inner {
        height: 32px;
        min-height: 32px;
        padding-bottom: 0 !important;
      }

      &__list {
        &:not(.choices__list--dropdown) {
          .choices__item {
            font-family: var(--font-main);
            font-weight: 600;
            font-size: 15px;
            padding-right: 20px !important;
          }
        }

        &.choices__list--dropdown {
          min-width: 200px;
          left: auto;
          right: 0;

          .choices__input {
            display: none !important;
          }
        }
      }
    }

    .choices[data-type*='select-multiple']::after,
    .choices[data-type*='select-one']::after {
      top: 16px;

      width: 4px;
      height: 4px;
    }
  }

  .choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
    max-height: 235px;

    @media (max-width: 490px) {
      max-height: 180px;
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    min-width: 10px;
    height: 10px;
    border: 1px solid var(--cl-black);
    margin-right: ac(10px, 8px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-black);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-black);
      opacity: 0;
      transition: opacity 0.3s ease;
      margin-bottom: 1px;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      font-size: 9px;
      transition: all 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 0;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    font-family: var(--font-second);
    color: var(--cl-black);

    a {
      font: inherit;
      color: var(--cl-black);
      text-decoration: underline;
      text-decoration-color: var(--cl-black);
      transition: all 0.25s ease;

      &:hover {
        color: var(--cl-tangerine);
        text-decoration-color: var(--cl-tangerine);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
    }

    &:hover {
      border-color: var(--cl-sunflower);
    }
  }
}

.dark-bg {
  .meta-checkbox {
    &__box {
      border-color: var(--cl-beige);

      .check-clip,
      .circle-clip {
        background-color: var(--cl-beige);
      }
    }

    &__text {
      color: var(--cl-beige);

      a {
        color: var(--cl-sunflower);
        text-decoration-color: var(--cl-sunflower);

        &:hover {
          color: var(--cl-tangerine);
          text-decoration-color: var(--cl-tangerine);
        }
      }
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  label,
  .label {
    font-family: var(--font-main);
    color: var(--cl-sunflower);
    font-size: ac(14px, 12px);
    font-weight: 600;
    padding-bottom: ac(13px, 6px);
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(14px, 10px);
    width: 100%;
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    font-family: var(--font-main);
    color: var(--cl-black);
    border: 1px solid var(--cl-black);
    border-radius: 0;
    transition: all 0.3s ease;
    padding: 4px ac(11px, 10px);
    cursor: pointer;
    position: relative;
    height: 32px;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
    }

    &:hover {
      border-color: var(--cl-sunflower);
      color: var(--cl-sunflower);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-tangerine) / 0.3);
    }
  }

  &__file-name {
    max-width: calc(100% - ac(122px, 114px));
    @mixin max-line-length-one;
    font-family: var(--font-second);
    font-size: ac(17px, 15px);
    font-weight: 400;
    color: var(--cl-black);
  }

  &__info {
    font-size: ac(14px, 12px);
    line-height: normal;
    font-weight: 400;
    font-family: var(--font-second);
    font-style: italic;
    color: var(--cl-black);
    position: absolute;
    top: 2px;
    right: 0;
    opacity: 0.6;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-sunflower);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-sunflower) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
}

.dark-bg {
  .meta-upload-file {
    &__input {
      color: var(--cl-beige);
      border: 1px solid var(--cl-beige);

      &:hover {
        border-color: var(--cl-sunflower);
        color: var(--cl-sunflower);
      }
    }

    &__file-name {
      color: var(--cl-beige);
    }

    &__info {
      color: var(--cl-beige);
    }

    &.drag {
      .meta-upload-file__input {
        background-color: var(--cl-sunflower);
        box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-sunflower) / 0.3);
      }
    }

    &.drop {
      &:not(.done) {
        .meta-upload-file__input {
          transform: scale(0.9);
        }
      }
    }
  }
}
