@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700&family=Manrope:wght@200;300;400;500;600;700;800&family=Outfit:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --cl-black: #1C1C1C;
  --cl-black-rgb: 0 0 0;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --cl-tangerine: #E58131;

  --cl-sunflower: #EABA40;

  --cl-beige: #EDE9E3;

  --cl-background: #F6F4F1;

  /*Classroom*/

  --font-main: 'Manrope', sans-serif;
  --font-second: 'DM Sans', sans-serif;
  --font-third: 'Outfit', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
