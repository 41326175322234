.insight-search-section {
  position: relative;
  z-index: 10;
  padding-top: ac(50px, 40px);
  padding-bottom: ac(39px, 40px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__search-title {
    font-weight: 600 !important;
    font-size: ac(24px, 22px);
    line-height: 1.42;
    font-family: var(--font-main);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__search-btn {
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ac(15px, 12px);
    cursor: pointer;
    width: 14px;
    height: 14px;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: opacity .3s ease;
      font-size: 13px;
      line-height: normal;
      font-weight: 600;
      cursor: pointer;
      opacity: 1;

      &.icon-plus {
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }
    }

    &:not(.active) {
      i.icon-plus {
        opacity: 0;
      }
    }

    &.active {
      i.icon-search {
        opacity: 0;
      }
    }
  }

  &__search-input {
    position: relative;
    z-index: 5;
    margin-top: -15px;
    max-height: 0;
    opacity: 0;
    transition: all .4s ease;
    margin-bottom: 0;
    width: 300px;
    overflow: hidden;

    &.active {
      margin-bottom: 30px;
      max-height: 64px;
      opacity: 1;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    @media (min-width: 1280px) {
      /*width: calc(100% + 5px);
      max-width: calc(100% + 5px);*/
      margin-left: 0;
    }

    &:not(:last-child) {
      margin-bottom: ac(80px, 40px);
    }

    .filters-slider {
      &__item {
        &.active {
          .filters-slider__item-btn {
            opacity: 1;
          }
        }

        input:checked + .filters-slider__item-btn {
          font-weight: 600 !important;
          letter-spacing: -0.4px;
        }
      }

      &__item-btn {
        /*opacity: 0.5;*/
        font-weight: 400;
        font-size: ac(17px, 15px);
        line-height: normal;
        font-family: var(--font-main);
        transition: all 0.2s ease;
        letter-spacing: -0.2px;
      }
    }
  }

  &__favorites {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 16px);

    &:not(:last-child) {
      margin-bottom: ac(80px, 40px);
    }

    .resource-card.full-type {
      .resource-card__content {
        @mixin min-media 1400 {
          padding-right: 26px;
        }
      }
    }

    @mixin min-media 951 {
      .resource-card {
        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 3;
        }

        &:nth-child(2) {
          grid-area: 1 / 3 / 2 / 4;
        }

        &:nth-child(3) {
          grid-area: 2 / 3 / 3 / 4;
        }
      }
    }

    @mixin media 951 {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .resource-card {
        &.full-type {
          grid-column: 1 / -1;
        }
      }
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__sort {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    @media (max-width: 374px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__sort-info {
    p {
      font-family: var(--font-second);
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__result {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 16px);

    @mixin min-media 951 {
      .resource-card {
        grid-column: span 2;

        &.full-type {
          grid-column: span 3;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    @mixin media 951 {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .resource-card {
        &.full-type {
          grid-column: 1 / -1;
        }
      }
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__load {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    font-family: var(--font-main);
    font-size: ac(20px, 18px);
    font-weight: 600;
    line-height: 1.6;
    z-index: 5;
    margin-left: auto;
    margin-right: auto;

    &:before {
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      height: 1px;
      background: var(--cl-black);
      transition: background-color 0.4s ease, height 0.2s ease;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      &:before {
        transition: background-color 0.3s ease, height 0.3s ease;
        height: 50%;
        background-color: var(--cl-tangerine);
      }
    }
  }
}
