.contact-section {
  padding-top: 0;

  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;

  &__color-bg {
    background: var(--cl-black);
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: ac(818px, 700px);
    margin-left: auto;
    margin-right: auto;
    padding-top: ac(50px, 40px);
    padding-bottom: ac(50px, 40px);
  }

  &__title {
    width: 100%;

    h2 {
      font-size: ac(30px, 24px);
      font-weight: 500;
      line-height: 133.33%;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    .filters-slider {
      &__item {
        &.active {
          .filters-slider__item-btn {
            opacity: 1;
            font-weight: 600;
          }
        }
      }

      &__item-btn {
        border-top-color: var(--cl-beige);
        color: var(--cl-beige);
        font-size: ac(22px, 17px);
        line-height: normal;
        font-weight: 400;
      }
    }
  }

  &__container {
    width: 100%;
    position: relative;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.3s ease;
    pointer-events: none;

    &.active {
      position: relative;
      left: auto;
      opacity: 1;
      pointer-events: all;
    }

    @mixin media 1250 {
      .meta-upload-file {
        label,
        .label {
          padding-bottom: 8px;
        }

        &__info {
          position: relative;
          margin-top: 5px;
          right: auto;
          top: auto;
        }
      }
    }

    .hbspt-form {
      width: 100%;
    }

    &.with-hbspt-form {
      background: var(--cl-beige);
      padding: ac(20px, 10px);
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 16px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(33px, 24px);
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .meta-checkbox-list__item:not(:last-child) {
      margin-bottom: ac(11px, 8px);
    }

    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__form-bottom-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: ac(40px, 0px);
    grid-column-gap: ac(22px, 16px);
    grid-row-gap: 20px;

    .meta-checkbox__text {
      text-align: right;
    }

    .btn {
      &__text {
        white-space: nowrap;
      }
    }

    @media (max-width: 541px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .meta-checkbox__text {
        text-align: left;
      }
    }
  }
}
