.text-image-section {
  position: relative;
  z-index: 10;
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);

  &__color-bg {
    background: var(--cl-beige);
    position: relative;
  }

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image {
    @mixin aspect-ratio 500, 572;
    width: 40.26%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.33%;
  }

  &__title {
    width: 100%;
    max-width: ac(450px, 340px);

    &:not(:last-child) {
      margin-bottom: ac(35px, 24px);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(50px, 28px);
    }
  }

  @media (max-width: 639px) {
    .text-image-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__content {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: ac(50px, 40px);
        }
      }

      &__image {
        width: 100%;
        max-width: 450px;
      }
    }
  }

  &.about-page {
    .text-image-section {
      &__title {
        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }
    }
  }

  &.bg-image-type {
    .text-image-section {
      &__color-bg {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 92.74%;
          z-index: 2;
          content: '';
          background: linear-gradient(
            270deg,
            rgba(28, 28, 28, 0.64) 0%,
            rgba(28, 28, 28, 0) 100%
          );
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: overlay;
          opacity: 0.25;
        }
      }

      &__bg {
        &:before {
          position: absolute;
          inset: 0;
          z-index: 2;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: soft-light;
          opacity: 0.5;

          @media (max-width: 639px) {
            opacity: .1;
          }
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-tangerine);
          mix-blend-mode: multiply;
          opacity: 0.5;
        }
      }

      &__wrap {
        position: relative;
        z-index: 10;
        padding-top: ac(50px, 40px);
        padding-bottom: ac(50px, 40px);
      }

      &__content {
        width: 48.79%;

        @media (max-width: 639px) {
          width: 100%;
        }
      }

      &__title {
        max-width: 100%;
      }
    }

    @media (max-width: 767px) {
      .text-image-section {
        &__wrap {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__content {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: ac(50px, 40px);
          }
        }
      }
    }

    &.diversity-page {
      .text-image-section {
        &__color-bg {
          &:before {
            content: none;
          }
        }

        &__bg {
          @media (max-width: 639px) {
            img {
              object-position: 80% 50%;
            }
          }

          @mixin min-media 1800 {
            img {
              object-position: top;
            }
          }
        }

        &__wrap {
          min-height: ac(550px, 450px);
          justify-content: flex-start;
          align-items: flex-start;
        }

        &__content {
          background: var(--cl-beige);
          padding-top: ac(50px, 24px);
          padding-bottom: ac(50px, 24px);
          padding-left: ac(47px, 20px);
          padding-right: ac(47px, 20px);
          width: auto;
          max-width: ac(720px, 400px);

          @mixin media 451 {
            margin-top: auto;
            width: 100%;
          }
        }

        &__title {
          width: calc(100% + ac(2px, 0px));

          h2 {
            br {
              + span {
                margin-left: ac(54px, 24px);

                @mixin media 425 {
                  margin-left: 0;
                }
              }
            }

            @mixin media 425 {
              font-size: 32px;
            }

            @mixin media 374 {
              font-size: 30px;
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(52px, 30px);
          }
        }
      }

      @media (max-width: 639px) {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.careers-page {
        .text-image-section {
          &__color-bg {
            &:before {
              content: none;
            }

            &:after {
              content: none;
            }
          }

          &__bg {
            &:after {
              content: none;
            }
          }

          &__content {
            background: var(--cl-black);

            @media (max-width: 639px) {
              margin-top: auto;
            }
          }

          &__title h2 br + span {
            margin-left: ac(28px, 20px);
          }

          &__wrap {
            padding-top: ac(47px, 40px);

            @media (max-width: 639px) {
              min-height: 720px;
            }

          }
        }

      }
    }

    &.sector-page {
      .text-image-section {
        &__color-bg {
          &:before {
            content: none;
          }
        }

        &__wrap {
          min-height: ac(663px, 500px);
          justify-content: flex-start;
          align-items: flex-end;

          @media (max-width: 767px) {
            align-items: flex-start;
            justify-content: flex-end;
          }
        }

        &__content {
          background: var(--cl-black);
          padding-top: ac(50px, 24px);
          padding-bottom: ac(50px, 24px);
          padding-left: ac(47px, 20px);
          padding-right: ac(47px, 20px);
          width: 100%;
          max-width: ac(850px, 490px);
        }

        &__title {
          width: calc(100% + ac(2px, 0px));

          h2 {
            br {
              + span {
                margin-left: ac(54px, 24px);

                @mixin media 425 {
                  margin-left: 0;
                }
              }
            }

            @mixin media 374 {
              font-size: 30px;
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(52px, 30px);
          }
        }

        &__buttons {
          margin-bottom: -2px;
          @mixin full-btn;
        }
      }

      @media (max-width: 639px) {
        padding-top: 0;
      }
    }
  }
}
