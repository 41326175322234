.encouragement-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  z-index: 10;
  overflow: hidden;

  &__color-bg {
    background: var(--cl-beige);
    overflow: hidden;
  }

  &__wrap {
    padding-top: ac(51px, 30px);
    padding-bottom: ac(50px, 30px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__title {
    h2 {
      br {
        + span {
          margin-left: ac(54px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__text {
    margin-top: ac(-4px, 0px);

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        width: 99%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        grid-row-gap: 30px;
        grid-column-gap: ac(40px, 20px);
        flex-wrap: wrap;
      }

      .slider-buttons {
        display: none;
      }

      .encouragement-section__item {
        width: calc(50% - ac(20px, 10px));

        @mixin media 1430 {
          width: calc(49.5% - ac(20px, 10px));
        }

        @mixin media 551 {
          width: 100%;
        }
      }
    }

    &.swiper-initialized {
      overflow: visible;

      .slider-buttons {
        .slider-btn {
          margin-top: 30px;
        }
      }

      @mixin media 551 {
        max-width: 350px;
        margin-left: 0;
      }
    }
  }

  &__item {
    border-left: 4px solid var(--cl-sunflower);
    padding-left: ac(25px, 16px);
    padding-top: ac(20px, 16px);
    padding-bottom: ac(20px, 16px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;

    &.sectors-type {
      border-color: var(--cl-tangerine);
      padding-top: 8px;

      .encouragement-section {
        &__item-title {
          padding-left: 44px;
        }
      }
    }

    &.active {
      .encouragement-section {
        &__hidden-item-text {
          opacity: 1;
          max-height: 1000px;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  &__item-icon {
    @mixin aspect-ratio 1, 1;
    width: 57px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: var(--cl-tangerine);

    img {
      top: 10px;
      left: 10px;
      width: 37px;
      height: 37px;
      object-fit: contain;
    }
  }

  &__item-title {
    font-size: ac(34px, 22px);
    line-height: 1.18;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: ac(32px, 18px);
    }
  }

  &__item-text {
    padding-right: 7px;
    width: 100%;

    &[data-simplebar] {
      max-height: calc((ac(18px, 16px) * 1.55) * 8.1);

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-beige);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-tangerine);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }

      @mixin media 425 {
        max-height: calc((ac(18px, 16px) * 1.55) * 10.1);
      }
    }
    
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__hidden-item-text {
    overflow: hidden;
    max-height: 0;
    margin: 0;
    opacity: 0;
    transition: max-height .4s ease, opacity .4s ease, margin .4s ease;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  
  &__item-more {
    font-size: ac(18px, 16px);
    line-height: ac(28px, 24px);
    text-decoration: underline;
    transition: all .3s ease;
    cursor: pointer;
    font-family: var(--font-main);
    font-weight: 600;

    &:hover {
      color: var(--cl-tangerine);
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.dark-bg {
    .encouragement-section {
      &__color-bg {
        background-color: var(--cl-black);
      }

      &__item {
        border-color: var(--cl-tangerine);
      }

      &__item-more {
        color: var(--cl-beige);

        &:hover {
          color: var(--cl-tangerine);
        }
      }
    }
  }
}
