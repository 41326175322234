.teams-section {
  position: relative;
  z-index: 10;
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);

  &__page-decor {
    @mixin aspect-ratio 662, 881;
    position: absolute;
    z-index: -1;
    /*top: ac(-170px, -100px);*/
    top: ac(-275px, -180px);
    width: ac(662, 400px);
    left: ac(-206px, -100px);

    @media (max-width: 639px) {
      left: 0;
      width: 88.8%;
      max-width: 400px;
    }
  }

  &__color-bg {
    background: var(--cl-sunflower);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(50px, 40px);
    padding-bottom: ac(25px, 40px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    @media (min-width: 640px) {
      max-width: calc(100% - 100px);
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__buttons {
    margin-left: auto;
    position: relative;
    z-index: 10;

    @media (max-width: 451px) {
      margin-left: 0;
    }
  }

  &__slider-buttons {
    .slider-btn {
      margin-bottom: 28px;
    }

    @media (min-width: 640px) {
      position: absolute;
      top: 0;
      z-index: 10;

      .slider-btn {
        margin: 0;
      }
    }
  }

  &__slider {
    margin-top: ac(-80px, -50px);
    padding-top: ac(80px, 50px);
    overflow: hidden;

    &:not(.swiper-initialized) {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: ac(0px, 4px);
    }

    @mixin media 451 {
      overflow: visible;
      max-width: 280px;
      margin-left: 0;
    }
  }

  &.about-page {
    padding-top: ac(42px, 20px);

    @media (max-width: 639px) {
      + .text-image-section.bg-image-type {
        margin-top: -40px;
      }
    }
  }

  &.team-member-page {
    .teams-section {
      &__color-bg {
        background-color: transparent;
      }

      &__wrap {
        padding-top: 0;
        padding-bottom: 0;
      }

      &__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;

        .teams-section__title {
          width: 100%;
          @media (min-width: 640px) {
            width: calc(100% - 300px);
          }

          &:not(:last-child) {
            @media (min-width: 640px) {
              margin-bottom: 0;
            }
          }
        }

        @media (max-width: 639px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__buttons {
        @media (min-width: 640px) {
          margin-top: 16px;
        }
      }

      &__slider {
        .team-card__title {
          font-size: ac(24px, 20px);
          line-height: 141.67%;
          font-weight: 500;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      &__slider-buttons {
        position: relative;
        top: auto;

        .slider-btn {
          margin-bottom: ac(35px, 28px);
        }
      }
    }
  }

  &.sector-page {
    z-index: 5;
    padding-top: ac(42px, 20px);
    .teams-section {
      &__slider {
        overflow: visible;

        .team-card {
          &__title {
            &:not(:last-child) {
              margin-bottom: ac(6px, 10px);
            }
          }

          &__subtitle {
            font-weight: 500;
            font-size: ac(24px, 22px);
            line-height: 1.42;
            font-family: var(--font-main);
          }
        }
      }

      &__wrap {
        padding-top: 0;
        padding-bottom: 0;
      }

      &__slider-buttons {
        @media (min-width: 640px) {
          top: ac(7px, 0px);
          right: 0;
          left: auto;
        }
      }
    }
  }

  &.classroom-page {
    padding-top: ac(45px, 20px);
    .teams-section {
      &__wrap {
        padding-top: 0;
        padding-bottom: 0;
      }

      &__page-decor {
        @mixin aspect-ratio 809.71, 798.11;
        position: absolute;
        z-index: -1;
        /*top: ac(-170px, -100px);*/
        top: ac(300px, -180px);
        width: ac(809.71, 400px);
        left: ac(-121px, -100px);

        @media (max-width: 639px) {
          left: 0;
          width: 88.8%;
          max-width: 400px;
        }
      }

      &__title {
        h2 {
          font-family: var(--font-third);

          .word {
            &:first-child {
              &:before {
                display: none !important;
              }

              margin-left: 0 !important;
            }
          }

          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }

          @media (max-width: 700px) {
            br {
              display: none;

              + span {
                margin-left: 0;
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }
      }

      &__slider {
        margin-top: 0;
        padding-top: 0;
        overflow: visible;
        width: 100%;
        max-width: 100%;
      }

      &__slider-buttons {
        position: relative;
        top: auto;
        left: auto;

        .slider-btn {
          margin-bottom: 0;
          margin-top: 28px;
        }
      }
    }
  }

  &.equities-page {
    z-index: 20;
    .teams-section {
      &__color-bg {
        background: var(--cl-black);
      }

      &__wrap {
        padding-top: ac(50px, 40px);
        padding-bottom: ac(50px, 40px);
      }

      &__slider-buttons {
        @media (min-width: 641px) {
          right: 0;
        }
      }
    }
  }

  &.pb-type {
    --none: 0;
    --small: ac(40px, 20px);
    --standard: ac(80px, 40px);
    --medium: ac(100px, 50px);
    --big: ac(120px, 60px);
    --biggest: ac(150px, 80px);

    --gap: var(--standard);

    padding-top: var(--gap);
    padding-bottom: 0;

  }
}
