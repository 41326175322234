/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---nb .cc-nb-main-container {
  padding-bottom: ac(50px, 24px);
  padding-top: ac(50px, 24px);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: 1px solid var(--cl-tangerine);
  background: var(--cl-background) !important;
  border-radius: 0;
  color: var(--cl-black);
  font-family: var(--font-main);

  @mixin min-media 652 {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @mixin media 651 {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin media 380 {
    margin-right: 0;
  }

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-transform: uppercase;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--cl-black) !important;
  }

  #cc-nb-text {
    font-size: ac(18px, 16px) !important;
    font-weight: 400 !important;
    line-height: 1.55 !important;
    font-family: var(--font-second) !important;
  }
  #cc-nb-title {
    font-weight: 600 !important;
    font-family: var(--font-main) !important;
    font-size: ac(40px, 24px) !important;
  }
}

.termsfeed-com---palette-dark .cc-pc-head-title-headline {
  color: var(--cl-black) !important;
  font-weight: 600 !important;
  font-family: var(--font-main) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-title-text {
  font-size: ac(18px, 16px) !important;
  font-weight: 400 !important;
  line-height: 1.55 !important;
  font-family: var(--font-second) !important;
  color: var(--cl-black) !important;
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;

    padding: ac(18px, 15px) 23px;
    height: ac(64px, 56px);
    transition: all .3s ease;
    background: var(--cl-tangerine) !important;
    border: 1px solid var(--cl-tangerine);
    font-family: var(--font-main);
    font-weight: 600;
    font-size: ac(20px, 18px);
    line-height: normal;
    color: var(--cl-black);
    text-transform: none !important;
    min-width: 140px;
    border-radius: 0;

    @mixin max-xs {
      font-size: 16px;
    }

    @media (max-width: 330px) {
      font-size: 15px;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      width: 148px;
      height: 170px;
      content: '';
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      background-image: url('../../assets/static/btn-bg-left.svg');
      background-size: cover;
      background-repeat: repeat;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(50%) translateY(-50%) rotate(45deg);
      width: 148px;
      height: 170px;
      content: '';
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      background-image: url('../../assets/static/btn-bg-right.svg');
      background-size: cover;
      background-repeat: repeat;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      border-color: var(--cl-sunflower);
      background-color: var(--cl-sunflower) !important;
      color: var(--cl-black);

      &:before {
        opacity: 0.5;
        transform: translateX(0) translateY(-50%) rotate(0);
      }

      &:after {
        opacity: 0.5;
        transform: translateX(0) translateY(-50%) rotate(0);
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .cc-nb-changep {
    background-color: var(--cl-beige) !important;
    border-color: var(--cl-beige) !important;

    &:hover {
      border-color: var(--cl-sunflower) !important;
      background-color: var(--cl-background) !important;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
  color: var(--cl-sunflower);
  transition: all 0.3s ease;
  text-transform: none;
  font: inherit;
  text-decoration: none;

  &:hover {
    color: var(--cl-tangerine);
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: 1px solid var(--cl-tangerine);
    border-radius: 0;
    overflow: hidden;
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--cl-background) !important;
    border-top-color: var(--cl-sunflower) !important;
    border-bottom-color: var(--cl-sunflower) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--cl-background);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3px;
    padding: 3px;
    border-right: 1px solid var(--cl-sunflower);
  }

  .cc-cp-body-tabs-item {
    background: var(--cl-beige);
    transition: all 0.3s ease;
    opacity: 1;
    border: none !important;
    border-radius: 0 !important;

    &:hover {
      opacity: 0.8;
      .cc-cp-body-tabs-item-link {
        color: var(--cl-sunflower) !important;
      }
    }
  }

  .cc-cp-body-tabs-item-link {
    color: var(--cl-black);
    transition: all 0.3s ease;

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--cl-black) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-background) !important;
    }

    &:hover {
      opacity: 1;

      .cc-cp-body-tabs-item-link {
        color: var(--cl-background) !important;
      }
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-black);
      padding: 5px;
      background: var(--cl-background);
      cursor: pointer;
      border: 1px solid var(--cl-black);

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--cl-sunflower);
    transition: all 0.3s ease;

    &:hover {
      color: var(--cl-tangerine);
      transform: scale(1.1);
    }

    &:focus {
      border: 0 !important;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--cl-sunflower);
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        color: var(--cl-tangerine);
        text-decoration-color: transparent;
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    padding: ac(18px, 15px) 23px;
    height: ac(64px, 56px);
    transition: all .3s ease;
    background: var(--cl-tangerine) !important;
    border: 1px solid var(--cl-tangerine);
    border-radius: 0;
    font: 600 var(--font-main), ac(20px, 18px) !important;
    font-weight: 600 !important;
    line-height: normal;
    color: var(--cl-black);
    text-transform: none !important;
    min-width: 140px;

    @mixin max-xs {
      font: 600 var(--font-main), 16px !important;
    }

    @media (max-width: 330px) {
      font: 600 var(--font-main), 15px !important;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      width: 148px;
      height: 170px;
      content: '';
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      background-image: url('../../assets/static/btn-bg-left.svg');
      background-size: cover;
      background-repeat: repeat;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(50%) translateY(-50%) rotate(45deg);
      width: 148px;
      height: 170px;
      content: '';
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      background-image: url('../../assets/static/btn-bg-right.svg');
      background-size: cover;
      background-repeat: repeat;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      border-color: var(--cl-sunflower);
      background-color: var(--cl-sunflower) !important;
      color: var(--cl-black);

      &:before {
        opacity: 0.5;
        transform: translateX(0) translateY(-50%) rotate(0);
      }

      &:after {
        opacity: 0.5;
        transform: translateX(0) translateY(-50%) rotate(0);
      }
    }

    @mixin media 530 {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

.termsfeed-com---palette-dark {
  .cc-cp-body-content-entry-text {
    color: var(--cl-black);
    font-weight: 500;
  }

  .cc-cp-body-content-entry-title {
    color: var(--cl-black);
    font-weight: 400;
    font-family: var(--font-second);
  }

  .cc-pc-head-title-text {
    color: var(--cl-black);
    font-weight: 500;
  }

  .cc-pc-head-title-headline {
    color: var(--cl-black);
    font-weight: 400;
    font-family: var(--font-second);
  }

  .cc-cp-foot-byline {
    color: var(--cl-black);
    font-weight: 500;
  }
}

.cc-custom-checkbox {
  label {
    font-weight: 500;
  }
}

.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox + label {
  color: var(--cl-black);

  &:after,
  &:before {
    border-color: var(--cl-orange);
  }
}

.termsfeed-com---pc-dialog
  input[type='checkbox'].cc-custom-checkbox:checked
  + label::before {
  background: var(--cl-orange);
}

/*
Styles for mobile adaptation
*/
@mixin media 651 {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }

  .termsfeed-com---pc-dialog .cc-pc-head-lang {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    justify-content: space-between;

    select {
      max-width: 200px;
    }
  }
}
