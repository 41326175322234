.text-block-section {
  position: relative;
  z-index: 10;
  padding-top: ac(42px, 20px);
  padding-bottom: ac(40px, 20px);

  &__page-decor {
    @mixin aspect-ratio 662, 881;
    position: absolute;
    z-index: -1;
    /*top: ac(-170px, -100px);*/
    top: ac(-275px, -180px);
    width: ac(662, 400px);
    left: ac(-206px, -100px);

    @media (max-width: 639px) {
      left: 0;
      width: 88.8%;
      max-width: 400px;
    }
  }

  &__color-bg {
    position: relative;
    background: var(--cl-beige);
  }

  &__wrap {
    display: flex;
    padding-top: ac(50px, 40px);
    padding-bottom: ac(50px, 40px);
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 38.77%;
    z-index: 1;
  }

  &__image {
    position: absolute;
    inset: 0;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-tangerine);
    padding-left: ac(50px, 20px);
    padding-right: ac(50px, 20px);
    padding-top: ac(38px, 24px);
    padding-bottom: ac(38px, 24px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 83.2%;
    z-index: 2;
  }

  &__item-title {
    font-size: ac(34px, 22px);
    line-height: 1.18;
    font-weight: 600;
    font-family: var(--font-main);

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.25%;
    margin-left: auto;
  }

  &__title {
    width: 100%;
    max-width: ac(450px, 340px);
    padding-left: 3px;
    margin-left: -3px;

    h2 {
      br {
        + span {
          margin-left: ac(28px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(33px, 24px);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: calc(100% + 1px);
    h1{
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      margin-bottom: 10px;
    }

    a:not([class]){
      font-size: inherit;
      font-weight: inherit;
      text-decoration: underline;
      transition: 0.3s;
      &:hover{
        text-decoration: none;
      }
    }
  }

  &__buttons {
  }

  &__values {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ac(71px, 40px);
    position: relative;
    border-top: 1px solid var(--cl-black);
    padding-top: ac(19px, 15px);
    cursor: pointer;

    &:not(.active) {
      cursor: pointer;
    }

    &:not(:last-child) {
      padding-bottom: ac(30px, 20px);
    }

    &.active {
      .text-block-section {
        &__hidden-value-text {
          padding-top: ac(10px, 8px);
          max-height: 1000px;
          overflow: visible;
          opacity: 1;
        }

        &__value-icon {
          i {
            transform: scaleY(-1);
          }
        }
      }
    }
  }

  &__value-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-beige);
    color: var(--cl-black);
    width: ac(41px, 30px);
    height: ac(41px, 30px);
    font-size: ac(17px, 11px);
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s ease;
    opacity: 0;

    i {
      transition: transform .3s ease;
    }

    &.with-btn {
      cursor: pointer;
      &:hover {
        background-color: var(--cl-white);
      }
    }
  }

  &__value-title {
    font-size: ac(30px, 22px);
    line-height: 133.33%;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__hidden-value-text {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height .3s ease, opacity .3s ease, padding-top .3s ease;
    padding-top: 0;
  }

  &__accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: ac(19px, 12px);
    transition: min-height 0.4s ease;
  }

  &__accordion-item {
    border-top: 1px solid var(--cl-black);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: border-color 0.4s ease, box-shadow 0.3s ease, padding 0.4s ease;

    &.active {
      .text-block-section__accordion-item-top {
        margin-bottom: ac(27px, 15px);
        margin-top: ac(-2px, 0px);
      }

      .text-block-section__accordion-item-btn {
        border-color: var(--cl-sunflower);
        background-color: var(--cl-sunflower);

        i {
          transform: scaleY(-1);
        }
      }

      .text-block-section__accordion-item-title {
        color: var(--cl-sunflower);
      }

      .text-block-section__accordion-item-content {
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;
      &:hover {
        border-color: var(--cl-tangerine);
        .text-block-section__accordion-item-title {
          color: var(--cl-tangerine);
        }
      }

      .text-block-section__accordion-item-content {
        max-height: 0 !important;
      }
    }
  }

  &__accordion-item-top {
    padding-top: ac(11px, 10px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
  }

  &__accordion-item-btn {
    --size: ac(41px, 38px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    border: 1px solid var(--cl-black);
    background: var(--cl-black);
    transition: all 0.4s ease;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    i {
      font-size: 16px;
      transition: all 0.4s ease;
      color: var(--cl-beige);
      pointer-events: none;
      z-index: 1;
    }

    &:hover {
      border-color: var(--cl-tangerine);
      background-color: var(--cl-tangerine);
      i {
        color: var(--cl-black);
      }
    }
  }

  &__accordion-item-title {
    max-width: calc(100% - 50px);
    font-size: ac(25px, 18px);
    font-weight: 400;
    line-height: 133.33%;
    transition: all 0.3s ease;
    color: var(--cl-black);
  }

  &__accordion-item-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    width: calc(100% + ac(30px, 20px));
    margin-left: ac(-15px, -10px);
    margin-right: auto;
    position: relative;
    padding-left: ac(15px, 10px);
    padding-right: ac(15px, 10px);
  }

  &__accordion-item-text {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(17px, 14px);
    }
  }
  
  /**/

  &__second-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: 10px;
    transition: min-height 0.4s ease;
  }

  &__second-item {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: background-color 0.4s ease, box-shadow 0.3s ease, padding 0.4s ease;
    position: relative;
    z-index: 2;

    &:after {
      z-index: -1;
      background: #F6F4F1;
      content: "";
      top: 0;
      bottom: 0;
      left: ac(-15px, -10px);
      right: ac(-15px, -10px);
      position: absolute;
      transition: all .3s ease;
    }

    &.active {
      .text-block-section__second-item-top {
        margin-bottom: ac(13px, 12px);
      }

      .text-block-section__second-item-btn {
        border-color: var(--cl-sunflower);
        background-color: var(--cl-sunflower);

        i {
          &.icon-plus {
            opacity: 0;
          }
        }
      }

      .text-block-section__second-item-title {
        color: var(--cl-sunflower);
      }

      .text-block-section__second-item-content {
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;
      &:hover {
        &:after {
          background-color: var(--cl-white);
        }
      }

      .text-block-section__second-item-content {
        max-height: 0 !important;
      }

      .text-block-section__second-item-btn {
        i {
          &.icon-minus {
            opacity: 0;
          }
        }
      }
    }
  }

  &__second-item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
  }

  &__second-item-btn {
    --size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    border: 1px solid var(--cl-black);
    background: var(--cl-black);
    transition: all 0.4s ease;
    position: relative;

    i {
      font-size: 10px;
      transition: all 0.4s ease;
      color: var(--cl-beige);
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }

    &:hover {
      border-color: var(--cl-tangerine);
      background-color: var(--cl-tangerine);
      i {
        color: var(--cl-black);
      }
    }
  }

  &__second-item-title {
    max-width: calc(100% - 50px);
    font-size: ac(20px, 18px);
    font-weight: 500;
    line-height: normal;
    transition: all 0.3s ease;
    color: var(--cl-black);
  }

  &__second-item-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__second-item-text {
    width: 100%;
  }

  &.classroom-page {
    padding-top: ac(50px, 20px);
    padding-bottom: ac(40px, 20px);

    .text-block-section {

      &__color-bg {
        background-color: transparent;
      }

      &__title {
        margin-top: 3px;
        max-width: 100%;

        h2 {
          font-family: var(--font-third) !important;

          .word {
            &:first-child {
              &:before {
                display: none !important;
              }

              margin-left: 0 !important;
            }
          }
        }
      }

      &__item {
        background-color: #023532;

        .link-btn {
          &:before {
            background-color: #CBE2B8;
          }

          .link-btn__text {
            font-family: var(--font-third) !important;
          }

          .link-btn__text, .link-btn__icon {
            color: #CBE2B8;
          }

          .link-btn__text {
            font-family: var(--font-third) !important;
          }
        }
      }

      &__item-title {
        color: #CBE2B8;
        font-family: var(--font-third) !important;
      }
    }

    @media (min-width: 768px) {
      .text-block-section {
        &__wrap {
          flex-direction: row-reverse;
        }

        &__image-container {
          left: auto;
          right: 0;
          width: 42.43%;
          max-width: 527px;
        }

        &__content {
          width: 57.33%;
          margin-left: 0;
          margin-right: auto;
        }
      }
    }

    @media (max-width: 639px) {
      padding-top: 0;

      .text-block-section {
        &__wrap {
          padding-bottom: 0;
        }
      }
    }
  }

  &.sectors-page {
    .text-block-section {
      &__item {
        left: auto;
        right: 0;
        max-width: 292px;
      }

      &__item-links {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title {
        max-width: 100%;

        h2 {
          br {
            + span {
              margin-left: ac(40px, 18px);
            }
          }
        }
      }

      &__text {
        max-width: 636px;
      }

      &__image-container {
        @media (min-width: 1280px) {
          width: 38.97%;
        }
      }

      &__image {
        img {
          object-position: left;
        }
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    .text-block-section {

      &__wrap {
        flex-direction: column;
      }

      &__image-container {
        width: 518px;
        height: 611px;
        bottom: auto;
      }

      &__image {
        img {
          object-position: top;
        }
      }

      &__content {
        width: 100%;
        padding-top: 600px;
      }
    }
  }

  @mixin media 551 {
    .text-block-section {
      &__image-container {
        width: 100%;
        right: 0;
        height: 110.89vw;
      }

      &__content {
        padding-top: 110vw;
      }
    }
  }

  @mixin media 390 {
    .text-block-section {
      &__image-container {
        height: 130vw;
      }

      &__item {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
      }

      &__content {
        padding-top: 128vw;
      }
    }
  }

  &.our-purpose-page.values-type {
    .text-block-section {
      &__color-bg {
        background: var(--cl-tangerine);
      }

      &__image-container {
        @media (min-width: 768px) {
          width: 39.45%;
        }

        @media (max-width: 767px) {
          height: 500px;
        }

        @mixin media 430 {
          height: 300px;
        }
      }

      &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 767px) {
          flex-direction: column;
        }
      }

      &__title {
        width: 32%;
        position: relative;
        z-index: 10;

        h2 {
          color: var(--cl-beige) !important;
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__content {
        width: 57.33%;

        @media (max-width: 767px) {
          width: 100%;
          padding-top: 400px;
        }

        @mixin media 430 {
          padding-top: 200px;
        }
      }

      &__image-container {
        &:before {
          position: absolute;
          inset: 0;
          z-index: 2;
          content: '';
          background: linear-gradient(
            180deg,
            rgba(28, 28, 28, 0.59) 0%,
            rgba(28, 28, 28, 0) 100%
          );
          mix-blend-mode: normal;
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: overlay;
          opacity: 0.25;
        }
      }

      &__image {
        &:before {
          position: absolute;
          inset: 0;
          z-index: 2;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: soft-light;
          opacity: 0.5;
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-tangerine);
          mix-blend-mode: multiply;
          opacity: 0.5;
        }
        
        @media (max-width: 451px) {
          img {
            object-position: 50% 35% !important;
          }
        }
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 0;
    }
  }

  &.image-bottom-type {
    .text-block-section {
      &__color-bg {
        background: var(--cl-black);
      }

      &__image-container {
        position: absolute;
        bottom: 0;
        top: auto;
        left: 0;
        right: 0;
        height: ac(250px, 150px);
        width: 100%;

        @mixin min-media 1921 {
          height: 300px;
        }
      }

      &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-bottom: ac(300px, 190px);

        @mixin min-media 1921 {
          padding-bottom: 350px;
        }
      }

      &__title {
        width: 100%;
        position: relative;
        z-index: 10;
        max-width: ac(820px, 500px);

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);

              @mixin media 551 {
                margin-left: 0;
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(26px, 24px);
        }
      }

      &__content {
        padding-top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          grid-row-gap: 24px;
        }
      }

      &__text {
        margin-top: ac(5px, 0px);
        width: 48.79%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &__buttons {
        width: 30%;
        justify-content: flex-end;

        @media (max-width: 767px) {
          width: 100%;
          justify-content: flex-start;
        }
      }

      &__image-container {
        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: overlay;
          opacity: 0.25;
        }
      }

      &__image {
        &:before {
          position: absolute;
          inset: 0;
          z-index: 2;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: soft-light;
          opacity: 0.5;
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-tangerine);
          mix-blend-mode: multiply;
          opacity: 0.5;
        }

        @mixin min-media 1800 {
          img {
            object-position: top;
          }
        }
      }
    }

    &.our-purpose-page {
      @media (min-width: 1280px) {
        padding-top: ac(99px, 40px);
      }

      .text-block-section__page-decor {
        @media (min-width: 1280px) {
          top: ac(250px, 100px);
        }
      }

      &.classroom-page {
        padding-top: ac(40px, 20px);

        .text-block-section {

          &__color-bg {
            background-color: #058668;
          }

          &__decor-bg {
            @mixin aspect-ratio 588.57,147.19;
            position: absolute;
            width: 44.05%;
            top: 0;
            right: 0;
            max-width: 600px;
          }

          &__image-container {
            max-width: 100%;
          }

          &__image {
            img {
              object-position: center !important;
            }

            &:after {
              z-index: 2;
            }

            &:before {
              z-index: 1;
              background: #023532;
              mix-blend-mode: multiply;
              opacity: .5;
            }
          }

          &__title {
            h2 {
              font-family: var(--font-third) !important;
              color: #CBE2B8;
            }
          }

          &__text {
            color: #CBE2B8;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            blockquote,
            li {
              color: #CBE2B8;
            }
          }
        }

        @media (max-width: 639px) {
          padding-top: 0;
        }
      }
    }

    @media (max-width: 639px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.testimonial-type {
    overflow: visible;
    position: relative;
    z-index: 2;

    .text-block-section {
      &__color-bg {
        background: var(--cl-black);
      }

      &__page-decor {
        @media (max-width: 639px) {
          display: none;
        }
      }

      &__wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        @media (min-width: 1280px) {
          padding-bottom: 45px;
        }
      }

      &__content {
        padding-top: 0;
        width: 100%;
        max-width: ac(1020px, 900px);
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__text {
        width: 100%;

        p {
          letter-spacing: 0.89px;
        }

        span {
          font-family: var(--font-main) !important;
        }
      }
    }

    @media (max-width: 639px) {
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.sector-page.values-type {
    .text-block-section {
      &__color-bg {
        background: var(--cl-tangerine);
      }

      &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }

      &__title {
        width: 100%;
        max-width: ac(750px, 450px);

        h2 {

          font-size: ac(68px, 38px);
          font-weight: 600;
          line-height: 1.11;
          font-family: var(--font-main);
          letter-spacing: -0.02em;

          br {
            + span {
              margin-left: ac(26px, 22px);
            }
          }
        }
      }

      &__text {
        width: 100%;
        max-width: ac(926px, 550px);

        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }

        &.big-size {
          @media (max-width: 451px) {
            font-family: var(--font-second);
            font-size: ac(18px, 16px);
            font-weight: 500;
            line-height: 1.55;

            p, li {
              font-family: var(--font-second);
              font-size: ac(18px, 16px);
              font-weight: 500;
              line-height: 1.55;
            }

            p, li, ul {
              max-width: 100%;

              strong {
                max-width: 100%;
                word-wrap: break-word;
                overflow-wrap: break-word;
              }
            }
          }
        }
      }

      &__content {
        padding-top: 0;
        width: 100%;
      }

      &__values {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: ac(30px, 20px);
        grid-row-gap: ac(41px, 24px);

        @media (max-width: 639px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__value {
        padding-bottom: 0;
        @mixin min-media 1400 {
          padding-left: 74px;
        }
      }

      &__value-icon {
        font-size: ac(20px, 12px);
      }

      &__value-text {
        &[data-simplebar] {
          max-height: calc((ac(18px, 16px) * 1.55) * 6.1);
          width: calc(100% + 10px);
          padding-right: 10px;

          .simplebar-track.simplebar-vertical {
            width: ac(4px, 3px);
            background: var(--cl-beige);
            border-radius: 0;
            .simplebar-scrollbar {
              background: var(--cl-sunflower);
              border-radius: 0;
              &:before {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  &.dark-bg.sector-page {
    .text-block-section {
      &__color-bg {
        background: var(--cl-black);
      }

      &__wrap {
        @mixin media 551 {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-top: 0;
        }
      }

      &__content {
        margin-left: 0;

        @media (min-width: 768px) {
          width: 48.79%;
        }

        @mixin media 551 {
          padding-top: 40px;
        }
      }

      &__title {
        max-width: 100%;
      }

      &__image-container {
        @media (min-width: 768px) {
          left: auto;
          right: 0;
          width: 43.19%;
        }

        &:before {
          position: absolute;
          inset: 0;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: overlay;
          opacity: 0.25;
          z-index: 3;
        }

        @mixin media 551 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-top: 240px;
          position: relative;
          inset: auto;
          width: calc(100% + 80px);
          margin-left: -40px;
          height: auto;

          &.not-image {
            padding-top: 0;
          }
        }
      }

      &__image {
        &:before,
        &:after {
          position: absolute;
          inset: 0;
          content: '';
        }

        &:before {
          background: var(--cl-tangerine);
          mix-blend-mode: multiply;
          opacity: 0.5;
          z-index: 1;
        }

        &:after {
          background: var(--cl-beige);
          mix-blend-mode: soft-light;
          opacity: 0.5;
          z-index: 2;
        }

        @mixin media 551 {
          height: 240px;
        }
      }

      &__item {
        width: 100%;
        z-index: 10;
        padding-left: ac(47px, 20px);
        padding-right: ac(47px, 20px);
        padding-top: ac(47px, 24px);
        padding-bottom: ac(47px, 24px);

        @media (min-width: 640px) {
          left: ac(-40px, -20px);
          bottom: 16px;

          @media (max-width: 767px) {
            left: auto;
            right: -20px;
          }
        }

        @mixin media 551 {
          position: relative;
          width: 100%;
          inset: auto;
          margin-top: auto;
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      &__item-text {
        &.big-size {
          p {
            font-weight: 500;
            font-size: ac(20px, 18px);
            line-height: 1.5;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        li {
          color: var(--cl-black) !important;
        }
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 0;
    }
  }

  &.accordion-type {
    padding-top: ac(40px, 20px);
    @media (max-width: 639px) {
      padding-bottom: 0;
    }

    .text-block-section {
      &__image-container {
        @media (min-width: 768px) {
          width: 33.17%;
          max-width: 412px;
        }

        @media (max-width: 767px) {
          img {
            object-position: center;
          }
        }
      }

      &__content {
        @media (min-width: 768px) {
          width: 65.86%;
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(48px, 30px);
        }
      }
    }


  }
}
