.placed-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);

  &__bg {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    inset: 0;

    &:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      background: var(--cl-black);
      opacity: .5;
      content: "";
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    padding-top: ac(50px, 24px);
    padding-bottom: ac(55px, 24px);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 52.33%;
  }

  &__title {
    h2 {
      br {
        + span {
          margin-left: ac(28px, 24px);
        }
      }
    }
  }

  &__buttons {
    justify-content: flex-end;
    width: 40.26%;
    margin-bottom: ac(-4px, 0px);
  }

  @media (max-width: 768px) {
    .placed-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__content {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      &__buttons {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: 421px) {
    .placed-section {
      &__buttons {
        .btn__text {
          font-size: 16px;
        }
      }
    }
  }
}