.teams-hub-section {
  padding-top: ac(130px, 50px);
  padding-bottom: ac(90px, 50px);
  position: relative;
  overflow: hidden;
  z-index: 10;

  + .text-block-section {
    padding-top: 0 !important;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(82px, 40px);
    }
  }

  &__search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(80px, 36px);
    }
  }

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(30px, 20px);
    grid-row-gap: 20px;

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;

    .filters-slider__item-btn {
      font-weight: 400;
      transition: all 0.25s ease;
    }

    .filters-slider__item input:checked + .filters-slider__item-btn {
      font-weight: 600;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: ac(30px, 20px);
    grid-row-gap: ac(40px, 24px);

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    @mixin media 951 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 639px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @mixin media 425 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      max-width: 288px;
    }
  }

  &__load {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    font-family: var(--font-second);
    font-size: ac(20px, 18px);
    font-weight: 600;
    line-height: 1.6;
    z-index: 5;

    &:before {
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      height: 1px;
      background: var(--cl-black);
      transition: background-color 0.4s ease, height 0.2s ease;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      &:before {
        transition: background-color 0.3s ease, height 0.3s ease;
        height: 50%;
        background-color: var(--cl-tangerine);
      }
    }
  }
}
