.sectors-section {
  padding-top: ac(100px, 50px);
  padding-bottom: ac(40px, 30px);
  z-index: 10;
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__title {
    h2 {
      br {
        + span {
          margin-left: ac(54px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__text {
    width: 100%;
    max-width: ac(1034px, 640px);

    &.first-big-paragraph {
      p:first-child {
        @media (min-width: 1280px) {
          padding-right: 10px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(.swiper-initialized) {
      overflow: visible;
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: ac(30px, 20px);
        grid-row-gap: ac(40px, 24px);

        @mixin media 1200 {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @mixin media 690 {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      .slider-buttons {
        display: none;
      }

      &.catalyst-type {
        .swiper-wrapper {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));

          @mixin media 901 {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            .sectors-section__item {
              &:nth-child(3):last-child {
                grid-column: 1/ -1;
              }
            }
          }
        }
      }
    }

    &.swiper-initialized {
      overflow: visible;

      .slider-buttons {
        .slider-btn {
          margin-top: 30px;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      .sectors-section__item-title {
        background-color: rgba(28, 28, 28, 0.9);
      }
    }
  }

  &__item-title {
    width: 100%;
    padding: ac(20px, 10px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 80px;
    background: var(--cl-black);
    transition: background-color .3s ease;

    h3 {
      font-size: ac(24px, 18px);
      line-height: 141.67%;
      font-weight: 600;
      color: var(--cl-beige) !important;
    }
  }

  &__item-icon {
    @mixin aspect-ratio 1, 1;
    width: ac(57px, 54px);
    background: var(--cl-tangerine);
    margin-bottom: auto;

    img {
      z-index: 5;
    }
  }

  &__item-link {
    min-height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-tangerine);
    transition: all .3s ease;
    padding: 4px 20px 6px;
    margin-left: auto;

    &:hover {
      background-color: var(--cl-sunflower);

      .link-btn__icon i {
        transform: translateY(ac(2px, 1px)) translateX(0) !important;
      }
    }
  }

  &.catalyst-page {
    .sectors-section {
      &__color-bg {
        background-color: transparent;
      }

      &__wrap {
        padding-top: 0;
        padding-bottom: 0;
      }

      &__item {
        align-items: flex-start;
        padding: ac(20px, 10px);
        background: var(--cl-black);
        transition: background-color .3s ease;
        padding-bottom: ac(116px, 100px);
        min-height: ac(394px, 300px);

        &:hover {
          background-color: rgba(28, 28, 28, 0.9);
        }
      }

      &__item-title {
        padding: 0;
        background: transparent !important;
        margin-bottom: 40px;
      }

      &__item-link {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--cl-tangerine);

        &:hover {
          background-color: var(--cl-sunflower);
        }
      }

      &__item-text {
        margin-top: auto;
        width: 100%;
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 40px !important;
    }
  }
}
