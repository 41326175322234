.support-section{
    padding: ac(50px, 40px) 0;
    .swiper{
        overflow: visible;
    }
    .slider-buttons{
        justify-content: center;

        .slider-btn{
            margin-top:ac( 45px, 30px);
        }
    }
}
.support-card{
    &-title{
        border-top: 2px solid var(--cl-tangerine);
        padding-top: 7px;
        color: var(--cl-tangerine);
        font-size: ac(30px, 20px);
        line-height: 1.333;
        margin-bottom: ac(30px, 20px);
        font-weight: 600;
    }
    &-description{
            --mb: 10px;
            --mb-count: 1;
            --line-count: 18;
            --fz: ac(18px, 16px);
            --line-height: 1.5555;
            line-height: var(--line-height);
            font-size: var(--fz);
            max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + var(--mb) * var(--mb-count));
        p{
            margin-bottom: var(--mb);
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
}