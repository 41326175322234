.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: ac(14px, 12px);

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease, opacity 0.3s ease;
    font-weight: 600;
    font-size: ac(24px, 22px);
    line-height: normal;
    opacity: 0.3;
    cursor: pointer;
    font-family: var(--font-main);

    &:hover {
      &:not(.active) {
        opacity: 0.5;
      }
    }

    &.active {
      opacity: 1;
    }

    &.with-points {
      opacity: 1;
      pointer-events: none;
      letter-spacing: 1.5px;
    }
  }
}
