.accordion-section {
    position: relative;
    z-index: 10;
    padding-top: ac(40px, 20px);
    padding-bottom: ac(40px, 20px);

    &__color-bg {
        position: relative;
        background: var(--cl-black);
    }

    &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: ac(50px, 40px);
        padding-bottom: ac(50px, 40px);
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 38.77%;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 38.49%;
    }

    &__title {
        width: 100%;
        max-width: ac(450px, 340px);

        h2 {
            br {
                + span {
                    margin-left: ac(56px, 24px);
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: ac(50px, 30px);
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__buttons {
    }

    &__accordion {
        margin-top: ac(47px, 30px);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        overflow: hidden;
        width: calc(57.33% + 6px);
        padding-right: 6px;
        min-height: auto;

        @media (min-width: 1280px) {
            margin-right: -5px;
        }
    }

    &__item {
        padding-top: ac(31px, 24px);
        padding-bottom: ac(28px, 24px);
        border-bottom: 1px solid var(--cl-beige);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        transition: all .3s ease;

        &:not(.active) {
            cursor: pointer;

            &:hover {
                border-color: var(--cl-tangerine);
            }
        }

        &.active {
            .accordion-section__item-content {
                width: calc(100% + 6px);
                margin-left: 0;
                padding-right: 6px;
                margin-top: ac(25px, 16px);
                max-height: 600px;
                opacity: 1;
            }

            .accordion-section__item-icon {
                i {
                    &.icon-plus {
                        opacity: 0;
                    }

                    &.icon-minus {
                        opacity: 1;
                    }
                }
            }

            .accordion-section__item-title {
                opacity: 1;
            }
        }
    }

    &__item-content {
        width: calc(100% + 6px);
        padding-right: 6px;
        transition: max-height 0.5s ease, opacity 0.4s ease, margin-top 0.2s ease;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }

    &__item-content-scroll {
        width: calc(100% + 6px);
        padding-right: 6px;
        max-height: 600px;

        .simplebar-track.simplebar-vertical {
            width: 3px;
            background: darkslategrey;
            border-radius: 3px;

            .simplebar-scrollbar {
                background: var(--cl-sunflower);
                border-radius: 0;

                &:before {
                    content: none;
                }
            }
        }
    }

    &__item-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        cursor: pointer;
    }

    &__item-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ac(25px, 20px);
        height: ac(25px, 20px);
        position: relative;
        cursor: pointer;
        min-width: ac(25px, 20px);

        i {
            font-size: ac(25px, 20px);
            color: var(--cl-beige);
            transition: color 0.3s ease;
            position: absolute;
            left: 0;
            top: 0;

            &.icon-minus {
                opacity: 0;
            }
        }

        &:hover {
            i {
                color: var(--cl-tangerine);
            }
        }
    }

    &__item-title {
        font-family: var(--font-main);
        font-size: ac(34px, 22px);
        line-height: 1.18;
        font-weight: 600;
        transition: opacity 0.3s ease;
        opacity: 0.5;
    }

    &__item-text {
        width: 100%;
        padding-left: 4px;

        &:not(:last-child) {
            margin-bottom: ac(14px, 12px);
        }
    }

    &__item-list {
        width: 100%;
        max-width: 610px;
        column-count: 2;
         display: block;
        @media (max-width: 1100px){
            column-count: 1;
        }

    }

    &__item-link {
        width: 100%;
        position: relative;
        padding-left: ac(29px, 18px);
        display: flex;
        align-items: center;
        justify-content: flex-start;


        &:before {
            content: '';
            position: absolute;
            left: ac(13px, 6px);
            top: 50%;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: var(--cl-beige);
        }

        a {
            margin-right: ac(8px, 4px);
        }

        i {
            font-size: 12.65px;
            color: var(--cl-beige);
            transition: transform 0.3s ease;
        }

        @mixin media 374 {
            padding-left: 12px;

            a {
                font-size: 14px !important;
            }

            i {
                font-size: 11px;
            }

            &:before {
                left: 3px;
            }
        }

        &:hover {
            i {
                transform: translateX(4px);
            }
        }
    }

    @media (max-width: 640px){
        .accordion-section {
            &__wrap {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }

            &__content {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 30px;
                }
            }

            &__accordion {
                margin-top: 0;
                width: calc(100% + 6px);
            }
        }
    }

    &:last-child {
        @media (max-width: 639px) {
            padding-bottom: 0;
        }
    }
}
