.article-section {
  position: relative;
  z-index: 10;
  padding-top: 50px;
  padding-bottom: ac(40px, 20px);

  @media (max-width: 767px) {
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }

  &__text {
    width: 57.33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(53px, 30px);
    }

    @mixin media 1200 {
      width: 65%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
    span{
      margin: 0!important;
      padding: 0!important;
      line-height: inherit!important;
      font: var(--font-main) inherit inherit!important;
      background: none!important;
    }
    li{
      margin: 0!important;
    }

  }

  &__team {
    display: flex;
    width: 31.72%;

    .team-card {
      width: 100%;

      &__image {
        @mixin aspect-ratio 394, 520;
        width: 100%;
      }

      &__subtitle {
        font-size: 16px;
        font-weight: 500;

        &:not(:last-child) {
          margin-bottom: ac(20px, 10px);
        }
      }
    }

    @mixin media 1400 {
      width: 28%;
    }

    @media (min-width: 768px) {
      position: sticky;
      top: 90px;
      right: 0;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 350px;
      margin-bottom: 40px;
    }
  }

  &__form-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__form-title {
    font-size: ac(34px, 22px);
    line-height: 1.18;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__form {
    width: 65.86%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    @mixin media 1250 {
      .meta-upload-file {
        label,
        .label {
          padding-bottom: 8px;
        }

        &__info {
          position: relative;
          margin-top: 5px;
          right: auto;
          top: auto;
        }
      }
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 16px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(33px, 24px);
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .meta-checkbox-list__item:not(:last-child) {
      margin-bottom: ac(11px, 8px);
    }

    .meta-checkbox {
      &__text {
        a {
          color: var(--cl-sunflower);
          text-decoration-color: var(--cl-sunflower);

          &:hover {
            color: var(--cl-tangerine);
            text-decoration-color: var(--cl-tangerine);
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__share {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    p {
      font-size: 14px;
      line-height: normal;
      color: var(--cl-sunflower);

      &:not(:last-child) {
        margin-bottom: ac(15px, 12px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ac(50px, 30px);
    border-top: 1px solid var(--cl-black);

    @media (max-width: 639px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 16px;
    }
  }

  &__links {
    grid-column-gap: ac(30px, 20px);
  }

  &__author {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(20px, 14px);
  }

  &__author-img {
    @mixin aspect-ratio 1, 1;
    width: ac(94px, 80px);
    border-radius: 50%;
  }

  &__author-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__author-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--cl-sunflower);

    &:not(:last-child) {
      margin-bottom: ac(7px, 6px);
    }
  }

  &__author-name {
    font-weight: 400;
    font-size: ac(20px, 18px);
    line-height: normal;
    font-family: var(--font-second);

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__author-subtitle {
    font-weight: 400;
    font-size: ac(17px, 16px);
    line-height: normal;
    font-family: var(--font-second);

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &.insight-details-page {
    padding-top: ac(80px, 50px);

    .article-section {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        max-width: ac(818px, 700px);
        margin-left: auto;
        margin-right: auto;
      }

      &__text {
        width: 100%;

        &.article-type {
          h3 {
            &:not(:last-child) {
              margin-bottom: ac(31px, 20px);
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(52px, 30px);
        }
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 40px;
    }
  }
}
