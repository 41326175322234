/* ----------------------- Common desktop header ----------------------- */

.top-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.3s ease;
  /*overflow: hidden;*/
  opacity: 1;
  background: transparent;
  height: ac(40px, 32px);

  &.fixed {
    position: fixed;
  }

  &:not(.scrolled) {
    @media (min-width: 640px) {
      margin-top: ac(52px, 40px);
    }
  }

  &.open-menu {
    @media (min-width: 640px) {
      margin-top: 0 !important;
      top: 0 !important;
    }
  }

  &.scrolled {
    background-color: var(--cl-tangerine);
    /*    margin-top: 0;
    max-height: 0;
    opacity: 0;*/
  }

  &__color-bg {
    background-color: var(--cl-tangerine);
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: ac(40px, 32px);
  }

  &__additional {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(24px, 16px);

    @media (max-width: 639px) {
      display: none;
    }
  }

  &__link {
    position: relative;
    font-size: ac(15px, 14px);
    font-weight: 600;
    line-height: 20px;
    /*transition: color .3s ease;*/
    z-index: 1;

    &:before {
      right: 0;
      left: 0;
      bottom: 0;
      height: 1px;
      background: var(--cl-sunflower);
      transition: height 0.3s ease, opacity 0.3s ease;
      content: '';
      position: absolute;
      z-index: -1;
    }

    &:hover {
      &:before {
        height: 50%;
      }
    }

    @media (max-width: 639px) {
      font-size: 15px;
    }
  }

  &__language {
    margin-left: ac(32px, 20px);
    display: flex;
    align-items: center;
    grid-column-gap: 8px;
    padding-right: 5px;
    padding-left: 8px;
    cursor: pointer;
    position: relative;
    font-size: ac(15px, 14px);
    font-weight: 600;
    line-height: 20px;

    i {
      font-size: 10px;
      transition: transform 0.3s ease;
    }

    @media (max-width: 639px) {
      font-size: 15px;
      margin-left: auto;

      i {
        font-size: 10px;
      }
    }

    @mixin min-media 1025 {
      &:hover {
        .top-header__language-list {
          max-height: 200px;
          opacity: 1;
        }

        i {
          transform: scale(1, -1);
        }
      }
    }

    @mixin media 1025 {
      &.active {
        .top-header__language-list {
          max-height: 200px;
          opacity: 1;
        }

        i {
          transform: scale(1, -1);
        }
      }
    }
  }

  &__language-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 0;
    transition: max-height 0.3s ease;
    padding-left: 12px;
    padding-right: 12px;
    position: absolute;
    bottom: -2px;
    right: 0;
    transform: translateY(100%);
    background-color: rgba(28, 28, 28, 0.8);
    z-index: 999;
    grid-row-gap: 4px;
    /*min-width: 80px;*/
    overflow: hidden;

    li {
      font-size: ac(15px, 14px);
      font-weight: 600;
      line-height: 20px;
      color: var(--cl-beige);
      transition: color 0.3s ease;

      @media (max-width: 639px) {
        font-size: 15px;
      }

      &:hover {
        color: var(--cl-tangerine);
      }
    }

    li {
      &:first-child {
        margin-top: 5px !important;
      }

      &:last-child {
        margin-bottom: 5px !important;
      }
    }
  }

  &__accessibility {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(40px, 32px);
    height: ac(40px, 32px);
    background: var(--cl-black);
    margin-left: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    i {
      font-size: ac(26px, 20px);
      color: var(--cl-white);
      transition: transform 0.2s linear;
    }

    &:hover {
      background-color: var(--cl-sunflower);

      i {
        transform: scale(1.1);
      }
    }
  }

  @media (min-width: 1450px) {

    .cont-second {
      max-width: calc(100% - 80px);
    }
  }
}

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  padding: ac(24px, 22px) 0 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  .cont-second {
    transition: border-color 0.3s ease, padding-bottom 0.3s ease;
    /*border-bottom: 1px solid var(--cl-beige);*/
    padding-bottom: ac(14px, 24px);
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: var(--cl-beige);
      transition: opacity 0.2s ease;
      content: '';

      @mixin mobile-menu-start-point {
        z-index: 1000;
      }
    }
  }

  &.fixed {
    position: fixed;
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.open-menu {
    @media (min-width: 640px) {
      margin-top: ac(40px, 32px) !important;
    }

    .cont-second {
      &:before {
        opacity: 1 !important;
      }
    }
  }

  &:not(.scrolled) {
    @media (min-width: 640px) {
      margin-top: calc(ac(40px, 32px) + ac(52px, 40px));
    }

    @media (max-width: 639px) {
      margin-top: ac(40px, 32px);
    }

    &.dark-type {
      .logo {
        .color-change {
          path {
            fill: var(--cl-black);
          }
        }
      }

      .cont-second::before {
        background-color: var(--cl-black);
      }

      &:not(.open-menu) {
        .burger,
        .menu-toggle {
          svg {
            path {
              stroke: var(--cl-black);
              stroke-width: 4px;
            }
          }
        }
      }

      @mixin mobile-menu-end-point {
        .menu__link {
          color: var(--cl-black);

          &:hover {
            color: var(--cl-sunflower);

            &:before {
              background-color: var(--cl-sunflower);
            }
          }
        }

        .menu-dropdown {
          .menu-dropdown__arrow {
            color: var(--cl-black);
          }

          &:hover {
            .menu-dropdown__arrow {
              color: var(--cl-sunflower);
            }
          }
        }

        .menu-dropdown__list {
          .menu__link {
            color: var(--cl-beige);

            &:hover {
              color: var(--cl-sunflower);

              &:before {
                background-color: var(--cl-sunflower);
              }
            }
          }
        }
      }

      @media (max-width: 639px) {
        &.open-menu {
          .logo {
            .color-change {
              path {
                fill: var(--cl-beige);
              }
            }
          }
        }
      }
    }
  }

  &.scrolled {
    &:not(.active) {
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      padding-top: ac(20px, 10px);

      .cont-second {
        /*border-bottom-color: transparent;*/
        padding-bottom: ac(20px, 10px);

        &:before {
          opacity: 0;
        }
      }

      &:before {
        opacity: 0.6;
        background-color: var(--cl-black);
      }

      .logo {
        /*width: 190px;

       @mixin max-xs {
         width: 165px;
       }*/

        .color-change {
          path {
            fill: var(--cl-beige);
          }
        }
      }

      /*.menu__link {
        color: var(--cl-white);

        &:hover {
          color: var(--cl-pink);
        }
      }

      .burger,
      .menu-toggle {
        svg path {
          &:not(.active) {
            stroke: var(--cl-white);

            &:hover {
              stroke: var(--cl-pink);
            }
          }
        }
      }*/
    }
  }

  /*&.white-type {
    &:not(.scrolled) {
      &:not(.active) {
        .logo {
          .color-change {
            path {
              fill: var(--cl-cream);
            }
          }
        }

        .menu__link {
          color: var(--cl-white);

          &:hover {
            color: var(--cl-pink);
          }
        }

        .burger,
        .menu-toggle {
          svg path {
            &:not(.active) {
              stroke: var(--cl-white);

              &:hover {
                stroke: var(--cl-pink);
              }
            }
          }
        }
      }
    }
  }*/

  .logo {
    height: 34px;
    width: 112px;
    flex-shrink: 0;
    transition: width 0.25s ease;
    z-index: 100;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ac(-8px, -5px);

    &:hover {
      img,
      svg {
        transform: scale(1.06);
      }
    }

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
      transition: transform 0.25s ease;

      .color-change {
        path {
          transition: all 0.3s ease;
          fill: var(--cl-beige);
        }
      }
    }

    @mixin max-xs {
      width: 180px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .burger {
    display: none;
  }

  &__navbar {
    display: flex;
    flex-grow: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }

    @mixin mobile-menu-end-point {
      max-width: calc(100% - 200px);
    }

    @mixin min-media 1400 {
      margin-bottom: -2px;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /*transition: opacity 0.2s ease;
    opacity: 0;*/

    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      align-items: center;

      .simplebar-content-wrapper,
      .simplebar-wrapper,
      .simplebar-mask {
        overflow: visible !important;
      }

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-column-gap: ac(24px, 20px);

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(32px, 20px);
        }

        &:after {
          display: none;
        }
      }
      .simplebar-track.simplebar-horizontal {
        display: none;
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .menu__item {
    position: relative;

    &.menu-dropdown {
      padding-right: 12px;
      position: relative;

      @media (max-width: 639px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      @mixin mobile-menu-end-point {

        &:not(.columns-type) {
          .menu__link {
            white-space: nowrap;
          }
        }
      }

      @mixin mobile-menu-start-point {
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .menu__link {
          white-space: nowrap;
        }

        .menu-dropdown__list {
          width: 100% !important;
        }

        @media (max-width: 639px) {
          justify-content: center;
        }
      }

      .menu-dropdown {
        &__arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--cl-beige);
          cursor: pointer;
          transition: all 0.3s ease;

          @mixin mobile-menu-end-point {
            position: absolute;
            top: 55%;
            transform: translateY(-50%) scale(1, 1);
            right: 0;
            font-size: 7px;
          }

          @mixin mobile-menu-start-point {
            transform: scale(1, 1);
            font-size: 12px;
            margin-left: 8px;

            &.active {
              transform: scale(1, -1);
            }
          }
        }



        &__list {
          transition: max-height 0.3s ease, opacity 0.3s ease,
            margin-top 0.3s ease;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          /*overflow-y: auto;
          scrollbar-width: thin;*/

          &::-webkit-scrollbar-button {
            display: none;
            background: red;
          }

          &::-webkit-scrollbar-button {
            height: 0;
            width: 0;
            opacity: 0;
          }

          &::-webkit-scrollbar {
            width: 2px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 0;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--cl-sunflower);
            border-radius: 0;
          }

          .scroll-container {
            max-height: 300px;
            padding-right: 15px;
            scrollbar-width: auto;
            overflow-y: auto;

            .os-scrollbar-handle {
              background: var(--cl-sunflower) !important;
            }

            .os-scrollbar-vertical {
              width: 8px !important;
            }

            /*li {
              white-space: nowrap;
            }*/

            @mixin mobile-menu-start-point {
              max-height: 1000px;

              li {
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                &:not(:last-child) {
                  margin-bottom: 15px;
                }
              }

              .os-scrollbar-handle {
                background: transparent !important;
              }
            }
          }


          @mixin mobile-menu-end-point {
            padding-left: 14px;
            padding-right: 14px;
            position: absolute;
            bottom: -2px;
            transform: translateY(100%);
            background-color: rgba(28, 28, 28, 0.8);
            z-index: 999;

            .menu__link {

              &:before {
                content: none;
              }

              &:first-child {
                margin-top: 5px !important;
              }

              &:last-child {
                margin-bottom: 5px !important;
              }
            }
          }

          /*.menu__link {
            white-space: nowrap;
          }*/

          @media (max-width: 639px) {
            align-items: center;
          }

          @mixin mobile-menu-start-point {
            padding-right: 10px;
            overflow: hidden;
            &.active {
              max-height: 800px;
              opacity: 1;
              margin-top: 15px;
              grid-row-gap: 15px;
            }
          }
        }

        &__list-columns {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          grid-column-gap: 10px;

          @mixin mobile-menu-start-point {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            grid-row-gap: 15px;
          }
        }

        &__list-item {
          padding-top: 4px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          min-width: ac(200px, 180px);
          padding-bottom: 2px;

          @mixin mobile-menu-start-point {
            padding-top: 0;
            padding-bottom: 0;
            width: 100%;

            &:not(:last-child) {
              padding-bottom: 15px;
              border-bottom: 1px solid var(--cl-beige);
            }
          }
        }

        &__list-title {
          color: var(--cl-beige);
          font-weight: 600;
          font-family: var(--font-main);
          font-size: ac(18px, 16px);
        }
      }

      &:hover {
        .menu-dropdown__arrow {
          color: var(--cl-tangerine);
        }

        @mixin mobile-menu-end-point {
          .menu-dropdown__arrow {
            transform: translateY(-50%) scale(1, -1);
          }

          .menu-dropdown__list {
            max-height: 300px;
            opacity: 1;
          }
        }
      }
    }
  }

  .menu__link {
    position: relative;
    font-size: ac(15px, 14px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-beige);
    transition: all 0.3s ease;
    cursor: pointer;
    font-family: var(--font-main);

    &:before {
      right: 0;
      left: auto;
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--cl-tangerine);
      opacity: 0;
      transition: width 0.3s ease, opacity 0.3s ease;
      content: '';
      position: absolute;
    }

    &:hover {
      color: var(--cl-tangerine);

      &:before {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
      }
    }
  }

  &__buttons {
    &.buttons {
      padding-top: 10px;
      display: none;
      margin-top: auto;

      @media (max-width: 639px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      @mixin media 551 {
        .btn {
          width: 100%;
          height: ac(64px, 40px);

          &__text {
            font-size: ac(20px, 16px);
          }
        }
      }
    }
  }

  @media (min-width: 1450px) {

    .cont-second {
      max-width: calc(100% - 80px);
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .logo {
      border: none;
    }

    &.active-top{

      .header__navbar{
        top: -32px;
      }
    }

    .header__navbar-cont {
      display: flex;
      flex-direction: column;

      height: 100%;
      margin: 0 auto;
      max-width: 1280px;
      width: perc(1280);

      @mixin max-lg {
        width: 89.33%;
      }
    }

    .header__navbar {
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
      min-width: 320px;
      padding-top: 190px;
      background: var(--cl-black);
      padding-right: ac(40px, 24px);
      padding-left: ac(40px, 24px);
      padding-bottom: 23px;
      transition: transform 0.3s ease-out;
      overflow: hidden;
      flex-direction: column;
      height: calc(var(--vh, 1vh) * 100);

      @media screen and (max-height: 740px) {
        padding-top: 130px;
      }

      @media (max-width: 767px) {
        padding-right: calc((100vw - 87.2vw - 4px) / 2);
        padding-left: calc((100vw - 87.2vw) / 2);
      }

      @media (max-width: 639px) {
        width: 100%;
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      .menu {
        max-height: 100%;
        margin-bottom: 10px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;

        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;

        overflow-x: hidden;
        overflow-y: auto;
        width: calc(100% + 10px);
        padding-right: 10px;

        .scroll-container {
          padding-right: 0 !important;
        }

        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .simplebar-track.simplebar-vertical {
          width: 4px;
          background: var(--cl-beige);
          border-radius: 0;

          .simplebar-scrollbar {
            background: var(--cl-sunflower);
            border-radius: 0;

            &:before {
              content: none;
            }
          }
        }

        &__item.hidden {
          display: block;

          &.first-hidden {
            margin-top: 28px;

            @media screen and (max-height: 730px) {
              margin-top: 10px;
            }

            @media screen and (max-height: 640px) {
              margin-top: 0;
            }
          }

          .menu__link {
            position: relative;

            &:before {
              left: 0;
              bottom: 0;
              right: 0;
              height: 1px;
              width: 100%;
              opacity: 1;
              background: var(--cl-sunflower);
              content: '';
              position: absolute;
              display: block;
            }
          }
        }
      }

      .menu__link {
        font-size: 20px;
        font-weight: 600;
        padding: 0;

        &:hover,
        &.active {
          color: var(--cl-tangerine);
        }

        &:before {
          display: none;
        }

        @media (max-width: 421px) {
          font-size: 18px;
        }
      }

      .menu-dropdown__list-title {
        font-size: 22px;
      }

      .menu__item {
        padding-bottom: 24.5px;

        @media (max-width: 639px) {
          margin-left: auto;
          margin-right: auto;
        }

        @mixin media-height 740 {
          padding-bottom: 20px;
        }

        @mixin media-height 700 {
          padding-bottom: 15px;
        }
      }
    }

    .menu-dropdown{
      width: 100%;
      .menu__link{
        white-space: initial !important;
      }
    }

    /*&.active {
      padding: 14px *;
      .logo {
        width: 215px;
      }
    }*/
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  z-index: 100;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-right: ac(10px, 8px);

  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.4);

  svg {
    width: 50px;
    min-width: 50px;
    height: 50px;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.4s;
    user-select: none;

    path {
      fill: none;
      stroke: var(--cl-beige);
      stroke-width: 4;
      transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;

      &.top {
        stroke-dasharray: 40 172;
      }

      &.bottom {
        stroke-dasharray: 40 172;
      }

      &.middle {
        stroke-dasharray: 40 172;
      }
    }
  }

  &.active {
    transform: rotate(45deg) scale(1.8);

    svg path {
      &.top {
        stroke-dashoffset: -68px;
      }

      &.bottom {
        stroke-dashoffset: -68px;
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
