.text-stats-section {
  padding-top: 36px;
  padding-bottom: ac(40px, 20px);
  z-index: 20;
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 48.79%;
  }

  &__title {
    h2 {
      br {
        + span {
          margin-left: ac(28px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(30px, 16px);
    }
  }

  &__text {
    &.first-big-paragraph p:first-child:not(:last-child) {
      margin-bottom: ac(26px, 24px);
    }
  }

  &__item {
    display: flex;
    padding-top: ac(51px, 30px);
    padding-bottom: ac(51px, 30px);
    padding-right: ac(47px, 20px);
    position: relative;
    z-index: 10;
    width: 44.04%;
    margin-right: ac(-47px, -20px);

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: ac(76px, 35px);
      background: var(--cl-tangerine);
      content: '';
      z-index: -1;
    }

    &[data-view] {
      &.animated {
        .text-stats-section {
          &__item-progressbar {
            span {
              width: var(--percent);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-beige);
    padding: ac(40px, 24px) ac(40px, 20px);
  }

  &__item-title {
    font-size: ac(34px, 26px);
    line-height: 1.18;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: ac(25px, 16px);
    }
  }

  &__item-text {
    &.big-size {
      p,
      li {
        font-weight: 400;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(25px, 16px);
    }
  }

  &__item-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-row-gap: ac(10px, 8px);
    grid-column-gap: ac(20px, 12px);
    padding-right: ac(50px, 0px);

    li {
      font-size: 14px;
      font-family: var(--font-main);
      line-height: normal;
      font-weight: 600;
      padding-left: ac(22px, 16px);
      position: relative;

      &:before {
        background: var(--cl-sunflower);
        width: ac(13px, 10px);
        height: ac(13px, 10px);
        border-radius: 50%;
        transform: translateY(-50%);
        left: 0;
        top: 54%;
        content: '';
        position: absolute;
      }

      &:nth-child(2) {
        &:before {
          background-color: #7bbdb1;
        }
      }

      &:nth-child(3) {
        &:before {
          background-color: #9ad1c7;
        }
      }

      &:nth-child(4) {
        &:before {
          background-color: #b1dcd4;
        }
      }

      &:nth-child(5) {
        &:before {
          background-color: #d0ebe6;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__item-progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-progressbar {
    width: 100%;
    height: ac(40px, 36px);
    border-radius: 20px;
    overflow: hidden;
    background: var(--cl-beige);
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    span {
      transition: width 0.9s ease, opacity 0.4s ease;
      background: var(--cl-sunflower);
      height: 100%;
      width: 0;
      opacity: 0;

      &:nth-child(2) {
        background-color: #7bbdb1;
      }

      &:nth-child(3) {
        background-color: #9ad1c7;
      }

      &:nth-child(4) {
        background-color: #b1dcd4;
      }

      &:nth-child(5) {
        background-color: #d0ebe6;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__item-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &:after,
    &:before {
      position: absolute;
      top: 0;
      width: 1px;
      height: 9px;
      content: '';
      background: var(--cl-black);
    }

    &:after {
      left: 0;
    }

    &:before {
      right: 0;
    }
  }

  &__item-min,
  &__item-max {
    margin-top: 9px;
    border-top: 1px solid var(--cl-black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 9px;
  }

  &__item-min {
    min-width: var(--percent);
    width: var(--percent);
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 9px;
      content: '';
      background: var(--cl-black);
      transform: translateY(-100%);
    }
  }

  &__item-max {
    flex: 1;
  }

  &__item-percent {
    font-size: ac(40px, 26px);
    line-height: 1.25;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    @mixin max-xs {
      font-size: 24px;
    }
  }

  &__item-name {
    font-size: 14px;
    font-family: var(--font-main);
    line-height: normal;
    font-weight: 600;
  }

  @mixin max-lg {
    .text-stats-section {
      &__item {
        width: 50%;
      }
    }
  }

  @media (max-width: 767px) {
    .text-stats-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__content {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      &__item {
        width: 100%;
        max-width: 420px;
        margin-right: 0;

        @mixin min-media 390 {
          padding-right: 30px;
        }
      }
    }
  }
}
