.testimonials-section {
  position: relative;
  z-index: 10;
  padding-top: ac(30px, 54px);
  padding-bottom: ac(40px, 30px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column-gap: ac(30px, 24px);

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &.careers-type {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;

      .slider-buttons {
        @media (min-width: 1023px) {
          position: absolute;
          top: -30px;
          right: 0;
        }

        @media (max-width: 1022px) {
          .slider-btn {
            margin-top: 30px;
          }
        }
      }
    }
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: ac(288px, 200px);

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__title {
    width: 100%;
    max-width: ac(260px, 200px);

    h2 {
      font-size: ac(40px, 32px);
      font-weight: 600;
      line-height: ac(50px, 40px);
    }

    &:not(:last-child) {
      margin-bottom: ac(29px, 22px);
    }
  }

  &__slider {
    margin-top: ac(11px, -5px);
    max-width: ac(447px, 400px);
    overflow: visible;
    margin-left: 0;
    clip-path: inset(-20px calc((100% + 50px) * -1) -20px -10px);

    &:not(.swiper-initialized) {
      display: none;
    }

    @mixin media 390 {
      max-width: 305px;
    }

    &.careers-type {
      width: 100%;
      max-width: 100%;
      clip-path: inset(-200px -10px -200px -10px);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding-left: ac(44px, 38px);
    border-top: 1px solid var(--cl-sunflower);
    padding-top: ac(19px, 15px);
    height: auto;
    cursor: default;
  }

  &__item-icon {
    color: var(--cl-tangerine);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ac(20px, 16px);
    left: 0;
    font-size: 22px;
  }

  &__item-text {
    width: 100%;

    &[data-simplebar] {
      max-height: calc((ac(18px, 16px) * 1.55) * 8.1);
      width: calc(100% + 10px);
      padding-right: 10px;

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-beige);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-sunflower);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(15px, 16px);
    }
  }

  &__item-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    grid-column-gap: ac(15px, 10px);
    grid-row-gap: ac(10px, 2px);
    margin-top: auto;
    width: 100%;
  }

  &__item-author,
  &__item-info {
    font-size: ac(15px, 14px);
    font-family: var(--font-main);
    line-height: 25px;
    font-weight: 500;
  }

  &__item-author {
    font-weight: 700;
  }

  &.sectors-page {
    .testimonials-section {
      &__color-bg {
        background: var(--cl-tangerine);
        overflow: hidden;
      }

      &__wrap {
        padding-top: ac(50px, 30px);
        padding-bottom: ac(50px, 30px);
      }

      &__item-icon {
        color: var(--cl-beige);
      }

      &__item {
        border-color: var(--cl-beige);
        transition: opacity 0.2s ease;

        &.swiper-slide-next {
          + .testimonials-section__item {
            opacity: 0;

            + .testimonials-section__item {
              opacity: 0;

              + .testimonials-section__item {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  &.careers-page {
    padding-top: ac(40px, 20px);

    .testimonials-section {

      &__color-bg {
        background: var(--cl-beige);
        overflow: hidden;
      }

      &__wrap {
        padding-top: ac(50px, 30px);
        padding-bottom: ac(50px, 30px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title-container {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: ac(17px, 18px);
        }
      }

      &__title {
        width: 100%;
        max-width: 100%;

        h2 {
          font-size: ac(70px, 38px);
          font-weight: 600;
          line-height: 1.11;
          font-family: var(--font-main);

          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(25px, 20px);
        }
      }

      &__slider {
        width: 100%;
        max-width: 100%;
      }

      &__item-icon {
        color: var(--cl-sunflower);
      }
    }

    &.careers-clients-page {
      .testimonials-section {

        &__color-bg {
          background: var(--cl-sunflower);
        }

        &__item-icon {
          color: var(--cl-beige);
        }

        &__item {
          border-color: var(--cl-beige);
          transition: opacity 0.2s ease;
        }
      }
    }

    + .text-block-section.image-bottom-type.our-purpose-page {
      padding-top: ac(40px, 20px);

      @media (max-width: 639px) {
        padding-top: 0;
      }
    }

    @media (max-width: 639px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @media (max-width: 425px) {
      .testimonials-section {
        &__title {
          h2 {
            br {
              display: none;

              + span {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
